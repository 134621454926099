.header-container {
  height: 100%;
  padding: 20px 50px;
}

.header-container .header-menu {
  display: flex;
  gap: 20px;
  width: 80%;
}

.header-container .search-form {
  flex-grow: 1;
}

.header-container .search-input {
  width: 600px;
}

.optional-menu,
.bar-btn {
  display: none !important;
}

/* -----------------------[ styles below 1100px ]----------------------- */

@media (max-width: 1100px) {
  .header-container .header-menu {
    width: 100%;
  }

  .header-container .search-input {
    width: 100%;
  }
}

/* -----------------------[ styles below 750px ]----------------------- */

@media (max-width: 750px) {
  .header-container {
    gap: 5px !important;
    height: 113px;
    padding: 10px 20px;
  }

  .header-container .header-menu {
    width: 100%;
    gap: 10px;
  }

  .header-container .search-input {
    width: 100%;
  }

  .default-menu {
    display: none !important;
  }

  .optional-menu,
  .bar-btn {
    display: block !important;
  }
}
