/* This file controls the desing of all the pages or form related to authentications */

.auth-form-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.auth-form-container .auth-form {
  background: rgb(53 59 129 / 10%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.1px);
  border-radius: 16px;
  width: 500px;
  padding: 40px;
}

.auth-form-container h1 {
  font-size: 25px;
  font-weight: normal;
  text-align: center;
  padding-bottom: 40px;
  color: #5f5f5f;
}

.auth-form-container .inputs {
  display: grid;
  gap: 20px;
}

.auth-form-container .navigation-link {
  color: #95e6f7;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.auth-form-container .navigation-link:hover {
  color: #42c0d9;
  text-decoration: underline;
}

@media (max-width: 520px) {
  .auth-form-container .auth-form {
    background: none;
    box-shadow: unset;
    backdrop-filter: unset;
    width: 100%;
    padding: 40px 0px;
  }
}

.css-pqhlcf-MuiInputBase-root-MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border-radius: 5px !important;
}

/* Login with Facebook class */
.facebook-button {
  padding: 4px;
  width: 214px;
  border-radius: 5px;
  background: white;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
