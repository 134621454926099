.dashboard-content-header {
  background: #090f2b;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0px;
  padding: 0px 20px;
  z-index: 10;
}

.scroll-box {
  margin: 40px;
  overflow-x: hidden;
  overflow-y: auto;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: 1fr 300px;
}

.scroll-box::-webkit-scrollbar {
  width: 7px;
}

.scroll-box::-webkit-scrollbar-track {
  background: #231153;
  border-radius: 10px;
}

.scroll-box::-webkit-scrollbar-thumb {
  background: #4b1cf4;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.scroll-box::-webkit-scrollbar-thumb:hover {
  background: #3c13d3;
}

/* -----------------------[ styles below 750px ]----------------------- */

@media (max-width: 750px) {
  .dashboard-content-header {
    padding: 10px 20px;
  }

  .scroll-box {
    margin: 0px;
  }
}

/* -----------------------[ styles below 1100px ]----------------------- */
@media (max-width: 1100px) {
  .dashboard-grid {
    display: flex;
  }
}

/* -----------------------[ styles 750px on onward ]----------------------- */
@media (min-width: 1400px) {
  .dashboard-grid {
    padding-right: 105px;
  }
}
