.recent-queries-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.paragraphs {
  font-size: 14px;
  padding: 20px;
  margin-bottom: 10px;
  line-height: 20px;
  color: #d9d9d9;
  border-radius: 8px;
  background-color: #090034;
  width: 688px;
  max-width: 100%;
}

.query-content-box {
  width: 100%;
  overflow-x: auto;
  border: 1px solid #545454;
}

.heading-box {
  border-bottom: 1px solid #545454;
}

.content:last-child {
  border-bottom: none;
}

.content {
  min-width: 688px;
}

.heading,
.content {
  display: grid;
  grid-template-columns: 200px 360px 128px;
}

.query-content-box .heading-item {
  border-right: 1px solid #545454;
  background-color: #090034;
}

.query-content-box .heading-item,
.query-content-box .content-item {
  color: #d9d9d9;
  display: flex;
  align-items: center;
  gap: 3px;
  padding: 13px;
  font-size: 13px;
}

.content-box:first-child {
  border-top: none;
}

.heading-item,
.content {
  border-bottom: 1px solid #545454;
  margin-bottom: 1px;
}

.query-content-box .actions {
  border-right: none;
}

.query-content-box .action-buttons {
  padding: 5px 10px;
}

.query-content-box::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.query-content-box::-webkit-scrollbar-thumb {
  background: #4b1cf4;
}
