/* Default Layout styles */

.scroll-box-container {
  height: 100vh;
  background: #090f2b;
  display: flex;
}

.right-content {
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 130px 1fr;
}

/* -----------------------[ styles below 750px ]----------------------- */

@media (max-width: 750px) {
  .right-content {
    grid-template-rows: 115px 1fr;
  }
}
