.sidebar-container {
  background-color: red;
  width: 80px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 10px;
  transition: all 0.3s;
}

.sidebar-background {
  display: none;
  transition: all 0.3s;
}

@media (max-width: 750px) {
  .sidebar-container {
    position: fixed;
    z-index: 100;
    transform: translateX(-100%);
  }

  .sidebar-translate {
    transform: translateX(0%);
  }

  .sidebar-background {
    display: block;
    background: #1e125300;
    width: 100vw;
    backdrop-filter: blur(3px);
    height: 100vh;
    position: fixed;
    z-index: 40;
  }

  .sidebar-hide {
    opacity: 0;
    pointer-events: none;
  }
}
