.dialogue-content {
  height: 100%;
  margin: 32px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease-in-out;
}

.dialogue-background {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  backdrop-filter: blur(2px);
  z-index: 3000;
}

.custom-dialogue-inner {
  background-color: #0e1847;
}

.custom-dialogue-inner {
  background: #0e1847;
  max-width: 90%;
  height: 90vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3000;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.dialogue-content::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.dialogue-content::-webkit-scrollbar-thumb {
  background: #4b1cf4;
}

@media (max-width: 600px) {
  .custom-dialogue-inner {
    width: 99vh;
    min-width: 95vw;
  }

  .dialogue-content {
    margin: 15px;
  }
}
