.layout-provider-scroll-box {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}

.scroll-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.layout-provider-scroll-box::-webkit-scrollbar {
  width: 7px;
}

.layout-provider-scroll-box::-webkit-scrollbar-track {
  background: #231153;
  border-radius: 10px;
}

.layout-provider-scroll-box::-webkit-scrollbar-thumb {
  background: #4b1cf4;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}

.layout-provider-scroll-box::-webkit-scrollbar-thumb:hover {
  background: #3c13d3;
}
