@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap");

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
}

.slide-up {
  animation: slideUp 0.4s ease-in-out;
}

.css-1glzd8c-MuiButtonBase-root-MuiButton-root {
  font-weight: normal !important;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
