.share-content-container {
  min-width: 450px;
  height: 100%;
  display: grid;
  grid-template-rows: 40px 1fr;
}

.share-content-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-bottom: none;
}

.users-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.share-content-inner {
  margin-top: 15 px;
  overflow: auto;
}

.share-content-inner .heading {
  font-size: 18px;
  color: #373737;
}

.share-content-footer {
  border-top: 1px solid #545454;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  width: 100%;
  height: 50px;
  position: sticky;
  bottom: 0px;
}

.share-content-inner::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.share-content-inner::-webkit-scrollbar-track {
  border-radius: 3px;
}

.share-content-inner::-webkit-scrollbar-thumb {
  background: #4b1cf4;
  border-radius: 5px;
}

@media (max-width: 600px) {
  .share-content-inner {
    width: 100%;
  }
}
