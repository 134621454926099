.alert-box {
  position: fixed;
  transition: all 0.1s ease-in-out;
  z-index: 200;
}

.alert-box .alert-bg {
  background-color: #00000000;
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
}

.alert-box .alert-content {
  min-width: 300px;
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: #0e1847;
  padding: 20px;
  border-radius: 10px;
}
