.search-content-container {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  position: relative;
}

@media (max-width: 600px) {
  .search-content-container {
    padding: 10px;
  }
}
