/* This file controls the desing of all the pages or form related to authentications */

.user-profile-form-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-profile-form-container
.css-pkqxp4-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: #a5a5a5;
  background-color: #1a255f;
}

.user-profile-form-container .profile-form {
  background: rgb(53 59 129 / 10%);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.1px);
  width: 500px;
  padding: 40px;
}

.user-profile-form-container h1 {
  font-size: 25px;
  font-weight: normal;
  text-align: center;
  padding-bottom: 10px;
  color: #5f5f5f;
}

.user-profile-form-container .inputs {
  display: grid;
  gap: 30px;
}

.user-profile-form-container .user-details {
  display: grid;
  gap: 15px;
  grid-template-columns: 100px 1fr;
}

/* -----------------------[ styles below 520px ]----------------------- */

@media (max-width: 520px) {
  .user-profile-form-container {
    width: 100%;
  }

  .user-profile-form-container .profile-form {
    width: 100%;
    padding: 40px 20px;
  }

  .user-profile-form-container .user-details {
    display: grid;
    gap: 15px;
    grid-template-columns: 60px 1fr;
  }
}

.css-pqhlcf-MuiInputBase-root-MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border-radius: 5px !important;
}
