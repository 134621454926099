.filter_input {
  border-radius: 2px;
  height: 20px;
  outline: none;
  border: none;
  background: #ebebeb;
  text-align: center;
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
}
