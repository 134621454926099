.dialog-content {
  background-color: #0e1847e3;
  padding: 20px; 
  border-radius: 10px;
}

.dialog-cover {
  width: 400px;
  border-radius: 15px;
  overflow: hidden;
  height: fit-content;
}

@media (max-width: 500px) {
  .dialog-cover {
    background-color: red;
    width: 350px !important;
  }
}
