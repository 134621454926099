
.panel-content {
  position: sticky;
  display: flex;
  flex-direction: column;
  gap: 10px;
  top: 40px;
  width: 300px;
  border-radius: 15px;
  background-color: #0e1847e3;
  backdrop-filter: blur(1px);
  text-align: center;
  padding: 20px;
  transition: opacity 0.3s ease-in-out;
}

.toggle-panel-button {
  color: #b7b7b7 !important;
  background-color: #111b4e !important;
  position: absolute !important;
  left: 0px;
  top: 50%;
  transform: translate(-110%, -50%);
  display: none !important;
}

.panel-button {
  color: #d9d9d9 !important;
  font-weight: normal !important;
  background-color: #111b4e !important;
  font-size: 14px !important;
  width: 100%;
}

/* -----------------------[ styles below 1100px ]----------------------- */
@media (max-width: 1100px) {
  .right-panel {
    position: fixed;
    right: 0px;
    transition: all 0.3s ease-in-out;
    transform: translateX(-70px);
  }

  .toggle-panel-button {
    display: flex !important;
  }

  .translate-panel {
    transform: translateX(100%) !important;
  }

  .translate-toggle-button {
    transform: translate(-45px, -50%);
  }
}

/* -----------------------[ styles below 750px ]----------------------- */
@media (max-width: 750px) {
  .right-panel {
    transition: all 0.3s ease-in-out;
    transform: translateX(-35px);
  }
}

/* -----------------------[ styles below 750px ]----------------------- */
@media (max-width: 400px) {
  .right-panel {
    right: -24px !important;
  }
}
